import React from 'react';
import axios from 'axios';
import moment from 'moment';
import data from '../data/data';
import Answers from './Answers'
import Popup from './Popup';
// import Footer from './Footer';


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nr: 0,
            total: data.length,
            showButton: false,
            questionAnswered: false,
            score: 0,
            displayPopup: 'flex'
        }
        this.nextQuestion = this.nextQuestion.bind(this);
        this.handleShowButton = this.handleShowButton.bind(this);
        this.handleStartQuiz = this.handleStartQuiz.bind(this);
        this.handleIncreaseScore = this.handleIncreaseScore.bind(this);
        this.sendToSlack = this.sendToSlack.bind(this)
    }

    pushData(nr) {
        this.setState({
            question: data[nr].question,
            answers: [data[nr].answers[0], data[nr].answers[1], data[nr].answers[2], data[nr].answers[3] ],
            correct: data[nr].correct,
            nr: this.state.nr + 1
        });

        
    }

    componentWillMount() {
        let { nr } = this.state;
        this.pushData(nr);
    }

    async sendToSlack(messageBody) {
        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            };
            await axios
            .post(
                "https://hooks.slack.com/services/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",
                JSON.stringify(messageBody),
                {
                headers: headers,
                }
            )
            .then((response) => {
                console.log(response.data);
                // response = response.data;
            })
            .catch((err) => console.log("err", err));
            console.log('corre',this.state)
    }

    nextQuestion() {
        let { nr, total, score } = this.state;
        if(nr === total){
            this.setState({
                displayPopup: 'flex'
            });
            const user = localStorage.getItem('slack-id')
            const messageBody = {
                username: "Challenge",
                text: `${user }  -  Just completed the JS/CSS/HTML challenge<!everyone> <!here> `, // <> are used for linking
                icon_emoji: ":moneybag:",
                attachments: [
                  // attachments, here we also use long attachment to use more space
                  {
                    color: "#2eb886",
                    fields: [
                      {
                        title: "Challenge ",
                        value: "HTML/CSS",
                        short: true,
                      },
                      {
                        title: "Time",
                        value: moment().format("MMMM Do YYYY, h:mm:ss a"),
                        short: true,
                      },
                      {
                        title: "CSS/HTML Result",
                        value: `${user}  got:  ${score}  out of  ${total}  questions right.`,
                        short: true,
                      },
                    ],
                  },
                ],
              };
            this.sendToSlack(messageBody)
        } else {
            this.pushData(nr);
            this.setState({
                showButton: false,
                questionAnswered: false
            });
        }

    }

    handleShowButton() {
        this.setState({
            showButton: true,
            questionAnswered: true
        })
    }

    handleStartQuiz() {
        this.setState({
            displayPopup: 'none',
            nr: 1
        });
    }

    handleIncreaseScore() {
        this.setState({
            score: this.state.score + 1
        });
    }

    render() {
        let { nr, total, question, answers, correct, showButton, questionAnswered, displayPopup, score} = this.state;

        return (
            <div className="container">

                <Popup style={{display: displayPopup}} score={score} total={total} startQuiz={this.handleStartQuiz} nr={nr}/>

                <div className="row">
                    <div className="col-lg-10 col-lg-offset-1">
                        <div id="question">
                            <h4>Question {nr}/{total}</h4>
                            <p>{question}</p>
                        </div>
                        <Answers answers={answers} correct={correct} showButton={this.handleShowButton} isAnswered={questionAnswered} increaseScore={this.handleIncreaseScore}/>
                        <div id="submit">
                            {showButton ? <button className="fancy-btn" onClick={this.nextQuestion} >{nr===total ? 'Finish quiz' : 'Next question'}</button> : null}
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
};

export default Main
