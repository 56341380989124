import React from 'react';

class Popup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            time: 'start',
            title: 'Welcome to HTML/CSS/JS  Challenge',
            text: 'Please enter your Slack fullname. e.g @Json Auth ' ,
            buttonText: 'Start the quiz',
            showButton:false,
            showText:false,
            slack:''
        };
        
        this.popupHandle = this.popupHandle.bind(this);
        this.postSlack =  this.postSlack.bind(this)
    }
    
    popupHandle() {
        let { time, slack } = this.state;
        if(!slack) {
            alert("Enter your slack name or ID")
            return false
        }

        localStorage.setItem('slack-id',this.state.slack)
        if(time === 'start'){
            this.setState({
                time: 'end',
                title: 'Congratulations!',
                buttonText: 'Done'
            });
            
            this.props.startQuiz();
        } else {      
            console.log(this.state)
            
            // window.location.reload();// restart the application
        }
    }

    postSlack (total) {
        if(this.props.nr === total && this.state.buttonText ==='Restart') {
            // alert('post it'+total)
        }
        
    }

    
    componentWillReceiveProps(nextProps) {
        
        this.setState({
            text: `You have completed the challenge. <br /> You got: <strong>  ${this.props.score}  </strong> out of <strong> ${this.props.total} </strong> questions right. `
        })
    }
    
    createMarkup(text) { 
        return {__html: text};
    }
    
    
    render() {
       console.log(this.state.slack)
        let { title, text, buttonText } = this.state;
        
        let { style } = this.props;
        
        return (
            <div className="popup-container" style={style}>
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popup">
                            <h1>{title}</h1>
                            <p dangerouslySetInnerHTML={this.createMarkup(text)} />
                            {/* <p style={{marginBottom:10}}>Please enter your Slack fullname <em>i:e @Jaspn Auth</em></p> */}
                            <div>
                                <input type={'text'} style={{width:300, height:35}} onChange={(e) => this.setState({slack: e.target.value})} />
                            </div>
                            <button className="fancy-btn" onClick={this.popupHandle}>{buttonText}</button>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup
