export default [
    {
        question: 'What does HTML stand for?',
        answers: ['Hyper Text Markup Language', 'Home Tool Markup Language', 'Hyper Texts Markup Language'],
        correct: 1
    },

    {
        question: 'Choose the correct HTML element for the largest heading:',
        answers: ['<head>', '<h1>', '<heading>', 'None of the above'],
        correct: 2
    },
    {
        question: 'What is the correct HTML element for inserting a line break?',
        answers: ['<break>', '<br>', '<lineBR>', 'br'],
        correct: 2
    },    
    {
        question: 'What is the correct HTML for adding a red background color?',
        answers: ['<body style="bg:red">', '<body bg=red>', '<body style="background-color:red">', '<body style="background:color:red">'],
        correct: 3
    },   

    {
        question: 'What is the correct HTML for creating a hyperlink?',
        answers: [' <a name="http://unlock.academy">Unlock.academy</a>', '<a href="http://unlock.academy">Unlock.academy</a>', '<a url="http://unlock.academy">Unlock.academy</a>', '<a>Unlock.academy</a>'],
        correct: 2
    },   
    {
        question: 'What does CSS stand for?',
        answers: ['Check Style Sheet', 'Computer Style sheets', 'Colourful Style Sheet', 'Cascading Style Sheet'],
        correct: 4
    },   

    {
        question: 'What is the correct HTML for referring to an external style sheet?',
        answers: [' <link src="stylesheet" type="text/css" href="mystyle.css">', ' <link rel="stylesheet" type="text/css">', '<script src="mycss.css"', '<link rel="stylesheet" type="text/css" href="mystyle.css">'],
        correct: 4
    },   

    {
        question: 'Where in an HTML document is the correct place to refer to an external style sheet?',
        answers: ['in the <head> section ', 'at the end of the document', 'in the <body> section', 'None of the above'],
        correct: 1
    },   

    {
        question: 'Which HTML tag is used to define an internal style sheet?',
        answers: ['<script>', '<style>', '<css>', 'all of the above'],
        correct: 2
    },   

    {
        question: 'Which property is used to change the background color?',
        answers: ['background-color', 'color', 'bg', 'bg-color'],
        correct: 1
    },   

    {
        question: 'What is the correct JavaScript syntax to change the content of the HTML element below?',
        answers: [' document.getElementByName("p").innerHTML = "Hello World!";', 'document.getElement("p").innerHTML = "Hello World!";', ' document.getElementByIds("demo").innerHTML = "Hello World!";', 'document.getElementById("demo").innerHTML = "Hello World!";'],
        correct: 4
    },   

    {
        question: 'What is the correct syntax for referring to an external script called "myscript.js"?',
        answers: ['<script src="myscript.js">', '<script href="myscript.js">', '<script src="myscript.js">', 'None of the above'],
        correct: 1
    },   

    {
        question: 'The external JavaScript file must contain the <script> tag.',
        answers: ['False', 'True',],
        correct: 1
    },   

    {
        question: 'How do you write "Hello World" in an alert box?',
        answers: ['alertBox("Hello World")', 'alertCode("Hello World")', 'alert("Hello World")', 'msg("hello World")'],
        correct: 3
    },   

    {
        question: 'How do you create a function in JavaScript?',
        answers: ['function myFunction()', 'function()', 'function  = myFunction()', 'None of the above'],
        correct: 1
    },   
    
    
    
    
]
